import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { getValidationSchemaLogin } from "../components/validations"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { Link as MaterialLink } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from '../components/dialog'
import { login as loginAction, finishLogin } from '../state/store'
import { useSelector, useDispatch } from 'react-redux'


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImageTrade: file(relativePath: { eq: "cedar-logo-trademark.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.logoImageTrade.childImageSharp.fixed} />
}

// const ForgotLink = ({props}) => <Link to="/forgot/" {...props}/>
const ALink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const LoginForm = ({ loginResult, submitting }) => {
  return (
    <Formik
      validationSchema={getValidationSchemaLogin}
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values, actions) => {
        loginResult(values)
        actions.setSubmitting(false)
      }}
    >
      {({
        errors,
        isSubmitting,
      }) => (
        <Form>
          <Field
            type="email"
            name="email"
            render={({ field, form }) => (
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  autoComplete="email"
                  // autoFocus
                  labelWidth={50}
                  required
                />
                <ErrorMessage name="email">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            )}
          />
          <Field
            type="password"
            name="password"
            render={({ field, form }) => (
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  type="password"
                  id="password"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  labelWidth={80}
                  required
                />
                <ErrorMessage name="password">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              isSubmitting || Object.keys(errors).length > 0 || submitting
            }
            type="submit"
          >
            Login
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Login = () => {
  // const state = useContext(GlobalStateContext)
  // const [dispatch] = useContext(GlobalDispatchContext)
  const {loggedIn, login}  = useSelector(state=>state.db)
  const dispatch = useDispatch()

  const classes = useStyles()

  useEffect(() => {
    if(loggedIn){
      navigate('/')
    }
  },[loggedIn])

  return (
    <Layout>
      <SEO title="Login" />
    <Container component="main" maxWidth="xs">
      {login && login.error && <AlertDialog title="Error" message={login.error.msg} closeDialog={()=>dispatch(finishLogin({loading: false, error: null}))}/>}
      {login && login.loading && <CircularProgress/>}
      <div className={classes.paper}>
        <Image />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <LoginForm loginResult={({email, password})=> dispatch(loginAction(email, password)) } submitting={login && login.loading}/>
        <Grid container style={{marginTop: '1rem'}}>
          <Grid item xs>
            <MaterialLink component={ALink} to="/forgot/" variant="body2">
              Forgot password?
            </MaterialLink>
          </Grid>
          <Grid item>
            <MaterialLink component={ALink} to="/register/" variant="body2">
              {"Don't have an account? Register"}
            </MaterialLink>
          </Grid>
        </Grid>
      </div>
    </Container>
    </Layout>
  )
}

export default Login
